import { Backdrop, CircularProgress, Container } from "@mui/material";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { RootState } from "../../services/storage/store";
import "./../../styles/index.scss";

// const theme = createTheme({
//   components: {
//     MuiContainer: {
//       styleOverrides: {
//         root: {
//           "&.MuiContainer-maxWidthXl": {
//             maxWidth: "1440px",
//           },
//         },
//       },
//     },
//   },
// });

export const PSContainer: React.FC = (): ReactElement => {
  const { loaderShowHide } = useSelector((state: RootState) => state.common);
  return (
    <Container maxWidth="xl">
      <Backdrop
        sx={(theme: { zIndex: { drawer: number } }) => ({
          color: "#fff",
          zIndex: theme.zIndex.drawer + 1,
        })}
        open={loaderShowHide}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Outlet />
    </Container>
  );
};
