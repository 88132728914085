import { Autocomplete, Grid2, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import _ from "lodash";
import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { PsButton, PsInput, StepBox } from "../../../lib/psElements/psElements";
import { useStudentProfileAPICalls } from "../../../services/api/profiledetails";
import { useStudentNaviagation } from "../../../services/hooks/student/navigationHooks";
import { RootState } from "../../../services/storage/store";
import { schoolDetails } from "../../../services/storage/student/profile";
import validation from "../../../services/validation";
import { GoBackArrowIos } from "../../common/GoBackButton/GoBackButton";
import StepsBar from "./StepsBar";

const SchoolDetails: FunctionComponent = () => {
  const navigate = useNavigate();
  const {
    getSchoolList,
    getSchoolBranchList,
    getCoachingClassList,
    getCoachingClassBranchList,
  } = useStudentProfileAPICalls();
  const studentNavigation = useStudentNaviagation();
  const {
    listOfSchoolName,
    school,
    profile,
    listOfSchoolBranch,
    listOfCoachingClassName,
    listOfCoachingClassBranch,
  } = useSelector((state: RootState) => state.studentProfile);
  const schoolName = String(profile.school.name).toString();
  const schoolBranch = String(profile.school.branch).toString();
  const className = String(profile.class.name).toString();
  const classBranch = String(profile.class.branch).toString();
  const [selecteSchoolOption, setSelecteSchoolOption] = useState(
    listOfSchoolName[0],
  );
  const [selecteSchoolBranchOption, setSelecteSchoolBrnachOption] = useState(
    listOfSchoolBranch[0],
  );

  const [selectClassOption, setSelectClassOption] = useState(
    listOfCoachingClassName[0],
  );

  const [selectClassBranchOption, setSelectClassBrnachOption] = useState(
    listOfCoachingClassBranch[0],
  );
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("School name in effect", schoolName);
    const indexNumber = _.findIndex(listOfSchoolName, (val) => {
      return String(val).toLowerCase() === String(schoolName).toLowerCase();
    });
    console.log("indexNumber", indexNumber);
    if (indexNumber > -1) {
      setSelecteSchoolOption(listOfSchoolName[indexNumber]);
    }
  }, [schoolName]);

  useEffect(() => {
    console.log("School name in effect", schoolBranch);
    const indexNumber = _.findIndex(listOfSchoolBranch, (val) => {
      return String(val).toLowerCase() === String(schoolBranch).toLowerCase();
    });
    console.log("indexNumber", indexNumber);
    if (indexNumber > -1) {
      setSelecteSchoolBrnachOption(listOfSchoolBranch[indexNumber]);
    }
  }, [schoolBranch]);

  useEffect(() => {
    getSchoolList();
    getCoachingClassList();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      schoolName: schoolName || "",
      schoolBranch: schoolBranch || "",
      className: className || "",
      classBranch: classBranch || "",
    },

    onSubmit: (values) => {
      const { schoolName } = values;
      dispatch(
        schoolDetails({
          ...profile,
          school: { ...profile.school, name: schoolName },
        }),
      );
      studentNavigation.navigateToBoardDetails();
    },
    validationSchema: Yup.object({
      schoolName: validation.schema.schoolName,
    }),
  });

  const handlerSchoolListOnChange = (e: any) => {
    console.log(e.target);
    const schoolName = e.target.innerText;
    formik.setValues({ ...formik.values, schoolName });

    dispatch(
      schoolDetails({
        ...profile,
        school: { ...profile.school, name: schoolName },
      }),
    );
    getSchoolBranchList(schoolName);
  };

  const handlerSchoolBranchOnChange = (e: any) => {
    console.log(e.target);
    const schoolBranch = e.target.innerText;
    formik.setValues({ ...formik.values, schoolBranch });
    dispatch(
      schoolDetails({
        ...profile,
        school: { ...profile.school, branch: schoolBranch },
      }),
    );
  };

  const handlerCoachingClassListOnChange = (e: any) => {
    console.log(e.target);
    const className = e.target.innerText;
    formik.setValues({ ...formik.values, className });

    dispatch(
      schoolDetails({
        ...profile,
        class: { ...profile.class, name: className },
      }),
    );
    getCoachingClassBranchList(className);
  };

  const handlerCoachingClassBranchOnChange = (e: any) => {
    console.log(e.target);
    const classBranch = e.target.innerText;
    formik.setValues({ ...formik.values, classBranch });
    dispatch(
      schoolDetails({
        ...profile,
        class: { ...profile.class, branch: classBranch },
      }),
    );
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="schoolDetails">
        <StepsBar currentStep={1} />
        <StepBox>
          <GoBackArrowIos
            handlerBackButton={studentNavigation.navigateToStep1PersonalDetails}
            sx={{ ml: 2, mt: 2, mb: 1 }}
          />
          <Typography
            align="left"
            sx={{ ml: 3, fontWeight: 700, mb: 2.5 }}
            variant="h5"
          >
            School
          </Typography>
          <Grid2
            container
            spacing={{ xs: 2, md: 2.5 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ m: 3, mb: 21.75 }}
          >
            <Grid2 size={{ md: 12 }} sx={{ mt: 1.5 }}>
              <Autocomplete
                disablePortal
                options={listOfSchoolName}
                onChange={(e) => handlerSchoolListOnChange(e)}
                value={selecteSchoolOption}
                renderInput={(params) => (
                  <PsInput
                    {...params}
                    error={!!formik.errors.schoolName}
                    {...formik.getFieldProps("schoolName")}
                    label="School"
                    helperText={
                      formik.touched.schoolName && formik.errors.schoolName
                        ? formik.errors.schoolName
                        : ""
                    }
                  />
                )}
              />
            </Grid2>
            <Grid2 size={{ md: 12 }} sx={{ mt: 1.5 }}>
              <Autocomplete
                disablePortal
                options={listOfSchoolBranch}
                onChange={(e) => handlerSchoolBranchOnChange(e)}
                value={selecteSchoolBranchOption}
                renderInput={(params) => (
                  <PsInput
                    {...params}
                    error={!!formik.errors.schoolBranch}
                    {...formik.getFieldProps("schoolBranch")}
                    label="School Branch"
                    helperText={
                      formik.touched.schoolBranch && formik.errors.schoolBranch
                        ? formik.errors.schoolBranch
                        : ""
                    }
                  />
                )}
              />
            </Grid2>
            {/* <Grid2 size={{ md: 12 }} sx={{ mt: 1.5 }}>
              <PsInput label="Attended any tuition or coaching classes" />
            </Grid2> */}

            <Grid2 size={{ md: 12 }} sx={{ mt: 1.5 }}>
              <Autocomplete
                disablePortal
                options={listOfCoachingClassName}
                onChange={(e) => handlerCoachingClassListOnChange(e)}
                value={selectClassOption}
                renderInput={(params) => (
                  <PsInput
                    {...params}
                    error={!!formik.errors.className}
                    {...formik.getFieldProps("className")}
                    label="Attended any tuition or coaching classes"
                    helperText={
                      formik.touched.className && formik.errors.className
                        ? formik.errors.className
                        : ""
                    }
                  />
                )}
              />
            </Grid2>
            <Grid2 size={{ md: 12 }} sx={{ mt: 1.5 }}>
              <Autocomplete
                disablePortal
                options={listOfCoachingClassBranch}
                onChange={(e) => handlerCoachingClassBranchOnChange(e)}
                value={selectClassBranchOption}
                renderInput={(params) => (
                  <PsInput
                    {...params}
                    error={!!formik.errors.classBranch}
                    {...formik.getFieldProps("classBranch")}
                    label="Coaching Classs Branch"
                    helperText={
                      formik.touched.classBranch && formik.errors.classBranch
                        ? formik.errors.classBranch
                        : ""
                    }
                  />
                )}
              />
            </Grid2>
          </Grid2>
        </StepBox>
        <Stack
          direction={"row"}
          gap={1}
          sx={{ mt: 4.5 }}
          alignSelf={"flex-end"}
        >
          <PsButton.Outlined
            variant="outlined"
            sx={{ width: 120 }}
            onClick={studentNavigation.navigateToDashboard}
          >
            Cancel
          </PsButton.Outlined>
          <PsButton.Primary
            variant="contained"
            sx={{ width: 240 }}
            type="submit"
            // onClick={studentNavigation.navigateToBoardDetails}
          >
            Proceed
          </PsButton.Primary>
        </Stack>
      </div>
    </form>
  );
};
export default SchoolDetails;
