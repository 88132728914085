import { FunctionComponent } from "react";
import backArrow from "../../assets/svg/authorization/arror-left.svg";
import { PsBackButtonLeftIos } from "../../../lib/psElements/psElements";
import { ArrowBackIosNew } from "@mui/icons-material";

interface goBackButtonProps {
  handlerBackButton?: (e: any) => void;
  props?: any;
  sx?: any;
}

const GoBackButton: FunctionComponent<goBackButtonProps> = ({
  handlerBackButton,
}) => {
  const handleBackButton = (e: any): void => {
    if (handlerBackButton && typeof handlerBackButton === "function")
      handlerBackButton(e);
  };

  return (
    <div
      className="goBackButton psButton hover"
      onClick={(e) => handleBackButton(e)}
    >
      <img src={backArrow} alt="Go back button"></img>
      <span className="ml-8">Go Back</span>
    </div>
  );
};

export const GoBackArrowIos: FunctionComponent<goBackButtonProps> = ({
  handlerBackButton,
  sx,
  props,
}) => {
  const handleBackButton = (e: any): void => {
    if (handlerBackButton && typeof handlerBackButton === "function")
      handlerBackButton(e);
  };
  return (
    <PsBackButtonLeftIos
      startIcon={<ArrowBackIosNew />}
      onClick={(e) => handleBackButton(e)}
      sx={sx}
      {...props}
    >
      Go Back
    </PsBackButtonLeftIos>
  );
};

export default GoBackButton;
