import { createSlice } from "@reduxjs/toolkit";

const common = createSlice({
  name: "common",
  initialState: {
    loaderShowHide: false,
  },
  reducers: {
    showHideLoader(state, action: { payload: boolean }) {
      state.loaderShowHide = action.payload;
    },
  },
});

export const { showHideLoader } = common.actions;
export default common.reducer;
