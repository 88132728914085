import { CardContent, Grid2, Stack, Typography } from "@mui/material";
import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BoardCard,
  GradeChip,
  PsButton,
  StepBox,
} from "../../../lib/psElements/psElements";
import { useStudentProfileAPICalls } from "../../../services/api/profiledetails";
import { RootState } from "../../../services/storage/store";
import { personalDetail } from "../../../services/storage/student/profile";
import { GoBackArrowIos } from "../../common/GoBackButton/GoBackButton";
import StepsBar from "./StepsBar";

const ExaminationDetails: FunctionComponent = () => {
  const navigate = useNavigate();
  const { getExamDateList } = useStudentProfileAPICalls();
  const { listOfExamDates, profile } = useSelector(
    (state: RootState) => state.studentProfile,
  );

  const dispatch = useDispatch();
  const examDate = profile.examDate;
  const boardNames = ["9", "10", "11", "12"];

  useEffect(() => {
    getExamDateList();
  }, []);

  const navigateToExaminationDate = () => {
    // navigate("/register/steps/examinationdate");
  };

  const navigateToGradeDetails = () => {
    navigate("/register/steps/gradedetails");
  };

  const handlerDateCardClick = (examDate: string) => {
    dispatch(personalDetail({ ...profile, examDate }));
  };

  const isDateCardSelected = (_examDate: string) => {
    if (examDate === String(_examDate).toLowerCase()) {
      return true;
    }
    return false;
  };

  return (
    <div className="schoolDetails">
      <StepsBar currentStep={5} />
      <StepBox>
        <GoBackArrowIos
          handlerBackButton={() => {
            navigateToGradeDetails();
          }}
          sx={{ ml: 2, mt: 2, mb: 1 }}
        />
        <Typography
          align="left"
          sx={{ ml: 3, fontWeight: 700, mb: 2.5 }}
          variant="h5"
        >
          When are you going to take your exam?
        </Typography>
        <Grid2
          container
          spacing={{ xs: 2, md: 2.5 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ m: 3, mb: 21.75 }}
        >
          {listOfExamDates.map((item: string) => {
            return (
              <Grid2 size={{ md: 3 }} sx={{ mt: 1.5 }}>
                <BoardCard
                  variant="outlined"
                  style={
                    isDateCardSelected(item)
                      ? { border: "1px solid #FD9B18" }
                      : {}
                  }
                  onClick={() => handlerDateCardClick(item)}
                >
                  <CardContent
                    sx={{
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <GradeChip label={item} sx={{ mb: 0.5 }} />
                    <Typography align="center">MAR 24</Typography>
                  </CardContent>
                </BoardCard>
              </Grid2>
            );
          })}
        </Grid2>
      </StepBox>
      <Stack direction={"row"} gap={1} sx={{ mt: 4.5 }} alignSelf={"flex-end"}>
        <PsButton.Outlined variant="outlined" sx={{ width: 120 }}>
          Cancel
        </PsButton.Outlined>
        <PsButton.Primary
          variant="contained"
          sx={{ width: 240 }}
          onClick={() => navigateToExaminationDate()}
        >
          Proceed
        </PsButton.Primary>
      </Stack>
    </div>
  );
};
export default ExaminationDetails;
