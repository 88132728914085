import * as ER from "@emotion/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PsRoutes from "./app/routes/PsRoutes";
import reportWebVitals from "./reportWebVitals";
import { psStore } from "./services/storage/store";
import { darkTheme } from "./styles/psMuiTheme/colorCodes";
import { psDarkTheme } from "./styles/psMuiTheme/psMuiTheme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Provider store={psStore}>
      <ThemeProvider theme={psDarkTheme} defaultMode="dark">
        <ER.ThemeProvider theme={darkTheme}>
          <ToastContainer />
          <CssBaseline />
          <PsRoutes />
        </ER.ThemeProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
