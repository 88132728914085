import { Box, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  OTPInput,
  PsButton,
  PsInput,
} from "../../../lib/psElements/psElements";
import { useAuthorizationAPICalls } from "../../../services/api/authorization";
import psValidate from "../../../services/validation";
import GoBackButton from "../../common/GoBackButton/GoBackButton";

interface phoneInputForLoginProps {
  formik: any;
}

interface oTPForPhoneNumberProps {
  phoneNumber: string;
  sendOtpOnMobile: any;
  handlerLoginWithPhoneAndOtp: any;
}
const OTPForPhoneNumber: FunctionComponent<oTPForPhoneNumberProps> = ({
  phoneNumber,
  sendOtpOnMobile,
  handlerLoginWithPhoneAndOtp,
}) => {
  const [otpString, setOtpString] = useState("");
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");

  let ref1 = useRef(null);
  let ref2 = useRef(null);
  let ref3 = useRef(null);
  let ref4 = useRef(null);
  let ref5 = useRef(null);
  let ref6 = useRef(null);

  useEffect(() => {
    setOtpString(otp1 + otp2 + otp3 + otp4 + otp5 + otp6);
  }, [otp1, otp2, otp3, otp4, otp5, otp6]);

  const handlerOTPInput = (e: any) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    const ename = name.split("-")[1];
    console.log(ename);
    switch (ename) {
      case "1":
        setOtp1(value);
        break;
      case "2":
        setOtp2(value);
        break;
      case "3":
        setOtp3(value);
        break;
      case "4":
        setOtp4(value);
        break;
      case "5":
        setOtp5(value);
        break;
      case "6":
        setOtp6(value);
        break;
      default:
        break;
    }
    // setOtpString(otp1 + otp2 + otp3 + otp4 + otp5 + otp6);
  };

  const handlerResendOtp = () => {
    sendOtpOnMobile();
  };

  const handlerLoginWithPhoneNumber = () => {
    console.log("length", otpString.length, ":", otpString);
    if (otpString.length === 6) handlerLoginWithPhoneAndOtp(otpString);
  };
  return (
    <>
      <Stack direction="column" sx={{ gap: "2.5rem" }}>
        <span className="leftAlign">
          An OTP has been sent to +91-{phoneNumber}
        </span>
        <div className="otpInputGroup">
          <OTPInput
            type="text"
            name="otp-1"
            onChange={(e) => handlerOTPInput(e)}
            value={otp1}
            inputRef={(ref) => {
              ref1 = { ...ref };
            }}
            inputProps={{ maxLength: 1 }}
          />
          <OTPInput
            type="text"
            name="otp-2"
            onChange={(e) => handlerOTPInput(e)}
            value={otp2}
            inputRef={(ref) => {
              ref2 = { ...ref };
            }}
            inputProps={{ maxLength: 1 }}
          />
          <OTPInput
            type="text"
            name="otp-3"
            onChange={(e) => handlerOTPInput(e)}
            value={otp3}
            inputRef={(ref) => {
              ref3 = ref;
            }}
            inputProps={{ maxLength: 1 }}
          />
          <OTPInput
            type="text"
            name="otp-4"
            onChange={(e) => handlerOTPInput(e)}
            value={otp4}
            inputRef={(ref) => {
              ref4 = ref;
            }}
            inputProps={{ maxLength: 1 }}
          />
          <OTPInput
            type="text"
            name="otp-5"
            onChange={(e) => handlerOTPInput(e)}
            value={otp5}
            inputRef={(ref) => {
              ref5 = ref;
            }}
            inputProps={{ maxLength: 1 }}
          />
          <OTPInput
            type="text"
            name="otp-6"
            onChange={(e) => handlerOTPInput(e)}
            value={otp6}
            inputRef={(ref) => {
              ref6 = ref;
            }}
            inputProps={{ maxLength: 1 }}
          />
        </div>
        <Typography
          align="right"
          className="cursorPointer"
          onClick={handlerResendOtp}
        >
          Resent OTP
        </Typography>
        <PsButton.Primary
          variant="contained"
          sx={{ height: "3rem" }}
          onClick={handlerLoginWithPhoneNumber}
        >
          Submit
        </PsButton.Primary>
      </Stack>
    </>
  );
};

const PhoneInputForLogin: FunctionComponent<phoneInputForLoginProps> = ({
  formik,
}) => {
  return (
    <Stack direction="column" sx={{ gap: "2.5rem" }}>
      <PsInput
        label="Phone Number"
        type="number"
        variant="outlined"
        {...formik.getFieldProps("phoneNumber")}
        error={!!formik.errors.phoneNumber}
        helperText={
          formik.touched.phoneNumber && formik.errors.phoneNumber
            ? formik.errors.phoneNumber
            : ""
        }
      />
      <PsButton.Primary
        variant="contained"
        className="hoverOrange"
        type="submit"
        sx={{ height: "3rem" }}
      >
        Send OTP
      </PsButton.Primary>
    </Stack>
  );
};

function LoginByPhoneNumber() {
  const [otpWindow, setOtpWindow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();
  const { getLoginOtpOnPhone, loginWithPhoneAndOtp } =
    useAuthorizationAPICalls();

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      setPhoneNumber(values.phoneNumber);
      sendOtpOnMobile(values.phoneNumber);
    },
    validationSchema: Yup.object({
      phoneNumber: psValidate.schema.mobile,
    }),
  });

  const sendOtpOnMobile = (phone: string) => {
    let tempPhoneNumber = phone ? phone : phoneNumber;
    getLoginOtpOnPhone({
      otp: "",
      mobile: tempPhoneNumber,
      loginMethod: "otp_mo",
    })
      .then((result) => {
        console.log(result);
        if (result?.data?.status === 200) {
          setOtpWindow(true);
        }
      })
      .catch((e) => console.log(e));
  };

  const handlerLoginWithPhoneAndOtp = (otpString: string) => {
    loginWithPhoneAndOtp({
      otp: otpString,
      mobile: phoneNumber,
      loginMethod: "otp_mo",
    });
  };

  const handlerBackButton = () => {
    if (otpWindow) setOtpWindow(false);
    else navigate("/authorization/login");
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box className="loginByPhoneNumber">
        <GoBackButton handlerBackButton={handlerBackButton} />
        <div>
          <h1 className="psHeading h1">Login with Phone Number</h1>
        </div>
        {!otpWindow && <PhoneInputForLogin {...{ formik }} />}
        {otpWindow && (
          <OTPForPhoneNumber
            {...{ phoneNumber, sendOtpOnMobile, handlerLoginWithPhoneAndOtp }}
          />
        )}
      </Box>
    </form>
  );
}

export default LoginByPhoneNumber;
