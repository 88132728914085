import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Input,
  styled,
  TextField,
} from "@mui/material";
// import { backgroundColor } from "../../styles/colorCode";
import { darkTheme, PsTheme } from "../../styles/psMuiTheme/colorCodes";

export const PsInput = styled(TextField)(({ theme }) => ({
  borderColor: PsTheme.colors.surface.four,
  // borderStyle: "solid",
  // borderWidth: "1px",
  // borderRadius: "0.5rem",
  label: {
    color: PsTheme.colors.font.dark,
  },
  input: {
    color: PsTheme.colors.font.dark,
  },
  width: "100%",
}));

export const PsBox = styled(Box)(({ theme }) => ({
  backgroundColor: darkTheme.colors.surface.five,
  color: darkTheme.colors.font.light,
}));

export const PsMainContainer = styled(Container)((theme) => ({
  // backgroundColor: darkTheme.colors.surface.root,
  background: "red",
}));

export const BlackButton = styled(Button)(({ theme }) => ({
  backgroundColor: PsTheme.colors.surface.two,
  minHeight: "2.5rem",
}));

export const PsButton = {
  Primary: styled(Button)({
    backgroundColor: PsTheme.colors.action.primary,
    height: 48,
  }),
  Outlined: styled(Button)({
    height: 48,
  }),
};

export const OTPInput = styled(Input)(({ theme }) => ({
  borderColor: PsTheme.colors.surface.four,
  borderStyle: "solid",
  borderWidth: "1px",
  borderRadius: "0.5rem",
  label: {
    color: PsTheme.colors.font.dark,
  },
  input: {
    color: PsTheme.colors.font.dark,
    textAlign: "center",
  },
  width: "3.5rem",
  height: "3.5rem",
  marginRight: "1rem",
  fontFamily: "inter",
  fontSize: "3rem",
}));

export const StepBox = styled(Box)(({ theme }) => ({
  background: PsTheme.colors.surface.one,
  height: "33.625rem",
}));

// <Button
//   component="label"
//   role={undefined}
//   variant="contained"
//   tabIndex={-1}
//   startIcon={<CloudUploadIcon />}
// >
//   Upload files
//   <VisuallyHiddenInput
//     type="file"
//     onChange={(event) => console.log(event.target.files)}
//     multiple
//   />
//   </Button>

export const PsBackButtonLeftIos = styled(Button)(({ theme }) => ({
  color: PsTheme.colors.action.secondary,
  alignSelf: "flex-start",
  display: "flex",
}));

export const BoardCard = styled(Card)(({ theme }) => ({
  background: PsTheme.colors.surface.two,
  width: 207,
  height: 140,

  avatar: {
    width: 80,
    Height: 80,
  },
}));

export const GradeChip = styled(Chip)(({ theme }) => ({
  height: 90,
  width: 90,
  borderRadius: 45,
  fontSize: 40,
  color: PsTheme.colors.boardCard.gradeFontColor,
  fontWeight: 700,
  backgroundColor: PsTheme.colors.boardCard.avatar,
  // marginTop: 6,
}));
