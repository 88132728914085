const ApiJson: any = {
  login: {
    url: "/api/auth/authenticate",
    method: "POST",
    data: {
      email: "",
      password: "",
      loginMethod: "",
    },
    showResultMessage: true,
    showErrorMessage: true,
  },
  student: {
    login: {
      email: {
        url: "/api/auth/authenticate",
        method: "POST",
        data: {
          email: "",
          password: "",
          loginMethod: "",
        },
        showResultMessage: true,
        showErrorMessage: true,
      },
      phone: {
        url: "/api/auth/send-otp",
        method: "POST",
        data: {
          otp: "",
          mobile: "",
          loginMethod: "otp_mo",
        },
        showResultMessage: true,
        showErrorMessage: true,
      },
      phoneAndOtp: {
        url: "/api/auth/authenticate",
        method: "POST",
        data: {
          otp: "",
          mobile: "",
          loginMethod: "otp_mo",
        },
        showResultMessage: true,
        showErrorMessage: true,
      },
    },
    profile: {
      get: {
        url: "/api/profile/user",
        method: "GET",
        data: {},
        showResultMessage: false,
        showErrorMessage: true,
      },
      board: {
        get: {
          url: "/api/board/all",
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
      },
      language: {
        get: {
          url: "/api/language/all",
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
      },
      grade: {
        get: {
          url: "/api/grade/all",
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
      },
      school: {
        get: {
          url: "/api/school/all",
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
        branch: {
          get: {
            url: "/api/school/branch?school=:schoolName",
            method: "GET",
            data: {},
            showResultMessage: false,
            showErrorMessage: true,
          },
        },
      },
      coachingclass: {
        get: {
          url: "/api/class/all",
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
        branch: {
          get: {
            url: "/api/school/branch?school=:className",
            method: "GET",
            data: {},
            showResultMessage: false,
            showErrorMessage: true,
          },
        },
      },
      examdate: {
        get: {
          url: "/api/examdate/all",
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
      },
    },
  },
};

export { ApiJson };
