import { Box, Stack, SvgIcon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userTypeEnum } from "../../../constants/enumDefinations";
import { BlackButton } from "../../../lib/psElements/psElements";
import { RootState } from "../../../services/storage/store";
import { personalDetail } from "../../../services/storage/student/profile";
import applesvg from "../../assets/svg/authorization/apple.svg";
import facebooksvg from "../../assets/svg/authorization/facebook.svg";
import googlesvg from "../../assets/svg/authorization/google.svg";
import GoBackButton from "../../common/GoBackButton/GoBackButton";

const SocialMedia = () => {
  return (
    <Box className="socialMedia">
      <div className="hover orange">
        <img src={googlesvg} alt="google" />
      </div>
      <div className="hover orange">
        <img src={applesvg} alt="apple" />
      </div>
      <div className="hover orange">
        <img src={facebooksvg} alt="facebook" />
      </div>
    </Box>
  );
};

function SelectLoginType() {
  const navigate = useNavigate();
  const { profile } = useSelector((state: RootState) => state.studentProfile);
  const dispatch = useDispatch();

  const userType = profile.userType;

  console.log("userType ", userType);
  // const handlerLoginType = () => {};

  const navigateToEmailLogin = () => {
    navigate("/authorization/email");
  };
  const navigateToPhoneLogin = () => {
    navigate("/authorization/phone");
  };

  // useEffect(() => {
  //   console.log("select login type ");
  //   // console.log(
  //   //   PsEncryption.getObject().getEncodeKey(),
  //   //   PsEncryption.getObject().getDecodeKey()
  //   // );

  //   setTimeout(async () => {
  //     console.log("this is encode decode example");
  //     console.log("Mahakali");
  //     const enc = PsEncryption.self().encrypt("Mahakali");
  //     const dec = PsEncryption.self().decrypt(enc);
  //     // const enc = await PsEncryption.getObject().encryptData("Mahakali");
  //     // const dec = await PsEncryption.getObject().decryptData(enc);
  //     console.log(enc, dec);
  //   }, 2000);
  // }, []);

  const handlerUserTypeSelection = (userType: userTypeEnum) => {
    dispatch(personalDetail({ ...profile, userType }));
  };

  return (
    <Box className="selectLoginType">
      <GoBackButton />
      <Stack direction="column" spacing={"1rem"}>
        <h1 className="psHeading h1">Login</h1>
        <Stack direction="row" spacing={"1.5rem"}>
          <BlackButton
            className="btnUserType hoverOrange"
            variant="contained"
            style={
              userType === userTypeEnum.student
                ? { border: "1px solid #FD9B18" }
                : {}
            }
            onClick={() => handlerUserTypeSelection(userTypeEnum.student)}
          >
            Student
          </BlackButton>
          <BlackButton
            variant="contained"
            className="btnUserType hoverOrange"
            style={
              userType === userTypeEnum.teacher
                ? { border: "1px solid #FD9B18" }
                : {}
            }
            onClick={() => handlerUserTypeSelection(userTypeEnum.teacher)}
          >
            Teacher
          </BlackButton>
        </Stack>
      </Stack>
      <Stack
        direction="column"
        sx={{ mt: "2.375rem", mb: "2.375rem", gap: "2.5rem", maxWidth: "100%" }}
      >
        <BlackButton
          className="btnSelectType hoverOrange"
          variant="contained"
          onClickCapture={() => navigateToPhoneLogin()}
          endIcon={
            <SvgIcon>
              {" "}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_163_1366)">
                  <path
                    d="M13.1687 12.0006L8.92566 7.75763C8.5352 7.36717 8.5352 6.7341 8.92566 6.34363C9.31613 5.95316 9.9492 5.95316 10.3397 6.34363L15.2896 11.2935C15.6801 11.684 15.6801 12.3172 15.2896 12.7077L10.3397 17.6576C9.9492 18.0481 9.31613 18.0481 8.92566 17.6576C8.5352 17.2672 8.5352 16.6341 8.92566 16.2436L13.1687 12.0006Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_163_1366">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </SvgIcon>
          }
        >
          By Phone Number
        </BlackButton>
        <BlackButton
          className="btnSelectType hoverOrange"
          variant="contained"
          endIcon={
            <SvgIcon>
              {" "}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_163_1366)">
                  <path
                    d="M13.1687 12.0006L8.92566 7.75763C8.5352 7.36717 8.5352 6.7341 8.92566 6.34363C9.31613 5.95316 9.9492 5.95316 10.3397 6.34363L15.2896 11.2935C15.6801 11.684 15.6801 12.3172 15.2896 12.7077L10.3397 17.6576C9.9492 18.0481 9.31613 18.0481 8.92566 17.6576C8.5352 17.2672 8.5352 16.6341 8.92566 16.2436L13.1687 12.0006Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_163_1366">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </SvgIcon>
          }
          onClickCapture={() => navigateToEmailLogin()}
        >
          By Email
        </BlackButton>
        <div className="orSeparator">
          <span>
            <hr />
            &nbsp;&nbsp;OR
            <hr />
          </span>
        </div>
      </Stack>

      <SocialMedia />
    </Box>
  );
}
export default SelectLoginType;
