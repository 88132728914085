import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { PsButton, PsInput } from "../../../lib/psElements/psElements";
import psApiService from "../../../services/api/apiService";
import psValidate from "../../../services/validation";
import { PsTheme } from "../../../styles/psMuiTheme/colorCodes";
import GoBackButton from "../../common/GoBackButton/GoBackButton";

const CreateAccount: FunctionComponent = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      vpassword: "",
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      // loginUsingEmailPassword({ ...values, loginMethod: "email" });
    },
    validationSchema: Yup.object({
      email: psValidate.schema.email,
      password: psValidate.schema.registerPassword,
      firstName: psValidate.schema.firstName,
      lastName: psValidate.schema.lastName,
      vpassword: psValidate.schema.password.oneOf(
        [Yup.ref("password")],
        psValidate.message.password.confirm,
      ),
    }),
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    // setShowPassword(true);
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    // setShowPassword(false);
  };

  const navigateToLoginByEmail = () => {
    navigate("/authorization/email");
  };
  const navigateToCongratulations = () => {
    navigate("/congratulations");
  };

  const navigateToLogin = () => {
    navigate("/authorization/login");
  };

  const handlerCreateAccount = () => {
    psApiService("register", {
      email: "testmate2024@gmail.com",
      password: "Test@1234",
      loginMethod: "email",
      firstName: "Test",
      lastName: "Mate",
    })
      .then((result) => console.log(result))
      .catch((error) => console.log(error));
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <GoBackButton handlerBackButton={navigateToLoginByEmail} />
        <Stack direction="column" sx={{ gap: "1.5rem" }}>
          <h1 className="psHeading h1">Create new Account</h1>
          <Stack direction="row" gap={"0.5rem"}>
            <PsInput
              type="text"
              label="First Name"
              {...formik.getFieldProps("firstName")}
              error={!!formik.errors.firstName}
              helperText={
                formik.touched.firstName && formik.errors.firstName
                  ? formik.errors.firstName
                  : ""
              }
            />
            <PsInput
              type="text"
              label="Last Name"
              {...formik.getFieldProps("lastName")}
              error={!!formik.errors.lastName}
              helperText={
                formik.touched.lastName && formik.errors.lastName
                  ? formik.errors.lastName
                  : ""
              }
            />
          </Stack>
          <PsInput
            type="email"
            label="Email Address"
            {...formik.getFieldProps("email")}
            error={!!formik.errors.email}
            helperText={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
          />
          <FormControl sx={{ m: 0, width: "100%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      showPassword
                        ? "hide the password"
                        : "display the password"
                    }
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              {...formik.getFieldProps("password")}
              error={!!formik.errors.password}
            />
            <FormHelperText>
              {formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ""}
            </FormHelperText>
          </FormControl>
          <PsInput
            type="password"
            label="Confirm Password"
            {...formik.getFieldProps("vpassword")}
            error={!!formik.errors.vpassword}
            helperText={
              formik.touched.vpassword && formik.errors.vpassword
                ? formik.errors.vpassword
                : ""
            }
          />
          <PsButton.Primary
            variant="contained"
            sx={{ height: "3rem" }}
            type="submit"
            // onClick={() => handlerCreateAccount()}
          >
            Create Account
          </PsButton.Primary>
          <Typography align="center">
            Already have a account?&nbsp;
            <span
              style={{ color: PsTheme.colors.action.primary }}
              className="cursorPointer"
              onClick={navigateToLogin}
            >
              Login
            </span>
          </Typography>
        </Stack>
        <Typography>
          By registering, you agree to the{" "}
          <span
            style={{ color: PsTheme.colors.action.primary }}
            className="cursorPointer"
          >
            Terms of Service and Privacy Policies,
          </span>{" "}
          including the use of Cookies
        </Typography>
      </Box>
    </form>
  );
};
export default CreateAccount;
