import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import leftBottomImage from "../../assets/images/authorization/boyGirlReadingBook.svg";
import logo from "../../assets/svg/logo.svg";

const RegistrationContainer: FunctionComponent = () => {
  const location = useLocation();
  const { pathname } = location;
  const leftBottomImageRef = useRef(null);
  const [leftBottomImageFlag, setLeftBottomImageFlag] = useState(false);

  useEffect(() => {
    if (pathname === "/register/congratulations") {
      setLeftBottomImageFlag(true);
    } else {
      setLeftBottomImageFlag(false);
    }
  }, [pathname]);

  return (
    <div className="registrationContainer">
      <img className="logo" src={logo} alt="Pariksathi logo" />
      <img
        ref={leftBottomImageRef}
        className={`sideImage ${leftBottomImageFlag ? "psHide" : ""}`}
        src={leftBottomImage}
        alt="girlBoyReading"
      />
      <Outlet />
    </div>
  );
};

export default RegistrationContainer;
