import { createSlice } from "@reduxjs/toolkit";

interface message {
  message?: string;
}

const error = createSlice({
  name: "error",
  initialState: {
    loginError: { message: "" },
  },
  reducers: {
    loginErrorMessage(state, action) {
      console.log("in reducer ", action);
      state.loginError.message = action.payload;
    },
  },
});

export const { loginErrorMessage } = error.actions;
export default error.reducer;
