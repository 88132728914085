import { ArrowBack, ArrowForward } from "@mui/icons-material";
import {
  Avatar,
  CardContent,
  Grid2,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BoardCard,
  PsButton,
  StepBox,
} from "../../../lib/psElements/psElements";
import { useStudentProfileAPICalls } from "../../../services/api/profiledetails";
import { RootState } from "../../../services/storage/store";
import { personalDetail } from "../../../services/storage/student/profile";
import { PsTheme } from "../../../styles/psMuiTheme/colorCodes";
import { GoBackArrowIos } from "../../common/GoBackButton/GoBackButton";
import StepsBar from "./StepsBar";

const LanguageDetails: FunctionComponent = () => {
  const navigate = useNavigate();
  const { getLanguageDetails } = useStudentProfileAPICalls();
  const { listOfLanguageNames, profile } = useSelector(
    (state: RootState) => state.studentProfile,
  );
  const [paginationCount, setPaginationCount] = useState(0);
  const [boardNames, setBoardNames] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // const boardNames = ["English", "Hindi", "Marathi", "Cannada", "Gujrati"];
  const dispatch = useDispatch();

  const language = String(profile.language).toLowerCase();

  useEffect(() => {
    getLanguageDetails();
  }, []);

  useEffect(() => {
    const count = listOfLanguageNames.length / 8;
    if (count !== paginationCount) setPaginationCount(count);
    changeListOnUserViews(currentPage);
  }, [listOfLanguageNames]);

  useEffect(() => {
    changeListOnUserViews(currentPage);
  }, [currentPage]);

  const changeListOnUserViews = (page: number) => {
    const tempList: any = _.slice(
      listOfLanguageNames,
      (page - 1) * 8,
      page * 8,
    );
    setBoardNames(tempList);
  };

  const handlerPageChange = (e: any, page: number) => {
    setCurrentPage(page);
  };

  const navigateToGradeDetails = () => {
    navigate("/register/steps/gradedetails");
  };

  const navigateToBoardDetails = () => {
    navigate("/register/steps/boarddetails");
  };

  const handlerLanguageCardClick = (language: string) => {
    dispatch(personalDetail({ ...profile, language }));
  };

  const isBoardNameSelected = (_language: string) => {
    if (language === String(_language).toLowerCase()) {
      return true;
    }
    return false;
  };

  return (
    <div className="schoolDetails">
      <StepsBar currentStep={3} />
      <StepBox>
        <GoBackArrowIos
          handlerBackButton={() => {
            navigateToBoardDetails();
          }}
          sx={{ ml: 2, mt: 2, mb: 1 }}
        />
        <Typography
          align="left"
          sx={{ ml: 3, fontWeight: 700, mb: 2.5 }}
          variant="h5"
        >
          In which language will you take your exams?
        </Typography>
        <Grid2
          container
          spacing={{ xs: 2, md: 2.5 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ m: 3, mb: 21.75 }}
        >
          {boardNames.map((item) => {
            return (
              <Grid2 size={{ md: 3 }} sx={{ mt: 1.5 }}>
                <BoardCard
                  variant="outlined"
                  onClick={() => handlerLanguageCardClick(item)}
                  style={
                    isBoardNameSelected(item)
                      ? { border: "1px solid #FD9B18" }
                      : {}
                  }
                >
                  <CardContent
                    sx={{ alignItems: "center", textAlign: "center" }}
                  >
                    <Avatar
                      sx={{
                        width: 80,
                        height: 80,
                        m: "auto",
                        backgroundColor: PsTheme.colors.boardCard.avatar,
                      }}
                      alt={item}
                      src="/static/images/avatar/1.jpg"
                    />
                    <Typography align="center" sx={{ width: "100%", mt: 1 }}>
                      {item}
                    </Typography>
                  </CardContent>
                </BoardCard>
              </Grid2>
            );
          })}

          {listOfLanguageNames.length > 8 && (
            <Pagination
              count={paginationCount}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBack, next: ArrowForward }}
                  {...item}
                  color="primary"
                  shape="circular"
                  selected={false}
                />
              )}
              onChange={handlerPageChange}
            />
          )}
        </Grid2>
      </StepBox>
      <Stack direction={"row"} gap={1} sx={{ mt: 4.5 }} alignSelf={"flex-end"}>
        <PsButton.Outlined variant="outlined" sx={{ width: 120 }}>
          Cancel
        </PsButton.Outlined>
        <PsButton.Primary
          variant="contained"
          sx={{ width: 240 }}
          onClick={() => navigateToGradeDetails()}
        >
          Proceed
        </PsButton.Primary>
      </Stack>
    </div>
  );
};
export default LanguageDetails;
