import { ArrowBack, ArrowForward } from "@mui/icons-material";
import {
  Avatar,
  CardContent,
  Grid2,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BoardCard,
  PsButton,
  StepBox,
} from "../../../lib/psElements/psElements";
import { useStudentProfileAPICalls } from "../../../services/api/profiledetails";
import { RootState } from "../../../services/storage/store";
import { personalDetail } from "../../../services/storage/student/profile";
import { PsTheme } from "../../../styles/psMuiTheme/colorCodes";
import { GoBackArrowIos } from "../../common/GoBackButton/GoBackButton";
import StepsBar from "./StepsBar";

interface boardNameItem {
  boardName: string;
  boardLogoUrl: string;
  boardUrl: string;
}

const BoardDetails: FunctionComponent = () => {
  const navigate = useNavigate();
  const { listOfBoardNames, profile } = useSelector(
    (state: RootState) => state.studentProfile,
  );
  const { getBoardDetails } = useStudentProfileAPICalls();
  const [paginationCount, setPaginationCount] = useState(0);
  const [boardNames, setBoardNames] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const boardName = String(profile.board).toLowerCase();

  useEffect(() => {
    const count = listOfBoardNames.length / 8;
    if (count !== paginationCount) setPaginationCount(count);
    changeListOnUserViews(currentPage);
  }, [listOfBoardNames]);

  useEffect(() => {
    getBoardDetails();
  }, []);

  useEffect(() => {
    changeListOnUserViews(currentPage);
  }, [currentPage]);

  const navigateToLanguageDetails = () => {
    navigate("/register/steps/languagedetails");
  };

  const navigateToSchoolDetails = () => {
    navigate("/register/steps/schooldetails");
  };

  const changeListOnUserViews = (page: number) => {
    const tempList: any = _.slice(listOfBoardNames, (page - 1) * 8, page * 8);
    setBoardNames(tempList);
  };

  const handlerPageChange = (e: any, page: number) => {
    setCurrentPage(page);
  };

  const handlerBoardCardClick = (boardName: string) => {
    dispatch(personalDetail({ ...profile, board: boardName }));
  };

  const isBoardNameSelected = (_boardName: string) => {
    if (boardName === String(_boardName).toLowerCase()) {
      return true;
    }
    return false;
  };

  return (
    <div className="schoolDetails">
      <StepsBar currentStep={2} />
      <StepBox>
        <GoBackArrowIos
          handlerBackButton={() => {
            navigateToSchoolDetails();
          }}
          sx={{ ml: 2, mt: 2, mb: 1 }}
        />
        <Typography
          align="left"
          sx={{ ml: 3, fontWeight: 700, mb: 2.5 }}
          variant="h5"
        >
          Which board are you going to write exam for?
        </Typography>
        {/* <Box sx={{ overflowY: "scroll", height: 400 }}> */}
        <Grid2
          container
          spacing={{ xs: 2, md: 2.5 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ m: 3, mb: 21.75 }}
        >
          {boardNames.map((item: any) => {
            if (item) {
              const { boardName, boardLogoUrl } = item;
              return (
                <Grid2 size={{ md: 3 }} sx={{ mt: 1.5 }}>
                  <BoardCard
                    variant="outlined"
                    onClick={() => handlerBoardCardClick(boardName)}
                    style={
                      isBoardNameSelected(boardName)
                        ? { border: "1px solid #FD9B18" }
                        : {}
                    }
                  >
                    <CardContent
                      sx={{ alignItems: "center", textAlign: "center" }}
                    >
                      <Avatar
                        sx={{
                          width: 80,
                          height: 80,
                          m: "auto",
                          backgroundColor: PsTheme.colors.boardCard.avatar,
                        }}
                        alt={boardName}
                        src={boardLogoUrl}
                      />
                      <Typography align="center" sx={{ width: "100%", mt: 1 }}>
                        {boardName}
                      </Typography>
                    </CardContent>
                  </BoardCard>
                </Grid2>
              );
            } else return null;
          })}
          {/* <Grid2 size={{ md: 12 }} sx={{ mt: 1.5 }}>
            <BoardCard variant="outlined">
              <CardContent sx={{ alignItems: "center", textAlign: "center" }}>
                <Avatar
                  sx={{ width: 80, height: 80, m: "auto" }}
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                />
                <Typography align="center" sx={{ width: "100%", mt: 1 }}>
                  ICSE
                </Typography>
              </CardContent>
            </BoardCard>
          </Grid2> */}
          <Pagination
            count={paginationCount}
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowBack, next: ArrowForward }}
                {...item}
                color="primary"
                shape="circular"
                selected={false}
              />
            )}
            onChange={handlerPageChange}
          />
        </Grid2>
        {/* </Box> */}
      </StepBox>
      <Stack direction={"row"} gap={1} sx={{ mt: 4.5 }} alignSelf={"flex-end"}>
        <PsButton.Outlined variant="outlined" sx={{ width: 120 }}>
          Cancel
        </PsButton.Outlined>
        <PsButton.Primary
          variant="contained"
          sx={{ width: 240 }}
          onClick={() => navigateToLanguageDetails()}
        >
          Proceed
        </PsButton.Primary>
      </Stack>
    </div>
  );
};
export default BoardDetails;
