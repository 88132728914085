import { ThemeProvider } from "@emotion/react";

export const darkTheme = {
  colors: {
    font: {
      light: "#F8F8F8",
      dark: "#C4C4C4",
    },
    surface: {
      root: "#131313",
      one: "#222222",
      two: "#2A2A2A",
      three: "#434343",
      four: "#494949",
      five: "#181818",
    },
    action: {
      primary: "#007fff",
      secondary: "#fd9b18",
      teritaryOne: "#f24e1e",
      teritaryTwo: "#3a3a3a",
    },
    chip: {
      green: "#0BAB48",
      blue: "#0B3D9F",
      violet: "#371648",
      brown: "#473319",
      red: "#751B1B",
    },
    alert: {
      green: "#02B223",
      red: "#E33F3F",
      blue: "#3587DC",
      orange: "#FD9B18",
    },
    button: {
      primay: "#1976D2",
      black: "#000000",
      // gray:rgba(255, 255, 255, 0.08),
      textWhite: "#FFFFFF",

      hoverBackground: "#FD9B1833",
      hoverBorderColor: "#FD9B18",
    },
    boardCard: {
      avatar: "#D9D9D9",
      gradeFontColor: "#3f7df9",
    },
  },
};

export const PsTheme = darkTheme;
/** Action colors will use for buttons and actions */
/* $action-primary-color: #007fff;
$action-secondary-color: #fd9b18;
$action-teritary-one-color: #f24e1e;
$action-teritary-two-color: #3a3a3a; */

/** Surface colors */
/* $surface-dark-one-color: #222222;
$surface-dark-two-color: #2A2A2A;
$surface-dark-three-color: #434343;
$surface-dark-four-color: #494949;
$surface-dark-five-color:  #181818;
; */

/** Typography colors */
/* $typography-white-one-color: #F8F8F8;
$typography-white-two-color: #C4C4C4;
 */
/** Chip colors */
/* $chip-green-color: #0BAB48;
$chip-blue-color: #0B3D9F;
$chip-violet-color: #371648;
$chip-brown-color: #473319;
$chip-red-color: #751B1B; */

/** Alert colors */
/* $alert-color-green: #02B223;
$alert-color-red: #E33F3F;
$alert-color-blue: #3587DC;
$alert-color-orange: #FD9B18; */

/** button colors */
/* $btn-primay-color: #1976D2;
$black-color: #000000;
$btn-gray-color:rgba(255, 255, 255, 0.08);
$btn-text-white: #FFFFFF;
$btn-hover-background: #FD9B1833;
$btn-hover-borderColor: #FD9B18; */

/** root background color */
/* $root-background: #131313; */
