import { configureStore } from "@reduxjs/toolkit";
import common from "./common";
import error from "./error";
import studentProfile from "./student/profile";
export const psStore = configureStore({
  reducer: {
    studentProfile: studentProfile,
    error: error,
    common: common,
  },
});

export type RootState = ReturnType<typeof psStore.getState>;
export type AppDispatch = typeof psStore.dispatch;
