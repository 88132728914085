import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLocalStorage, showErrorToast } from "../commonFunctions";
import { useStudentNaviagation } from "../hooks/student/navigationHooks";
import { showHideLoader } from "../storage/common";
import { loginErrorMessage } from "../storage/error";
import psApiService from "./apiService";

interface loginUsingEmailPasswordApiParams {
  email: string;
  password: string;
  loginMethod: string;
}
interface loginByPhoneApiParams {
  mobile: string;
  otp: string;
  loginMethod: string;
}

export const useAuthorizationAPICalls = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const studentNavigate = useStudentNaviagation();

  const navigateToProfileSteps = () => {
    navigate("/register/steps/personaldetails");
  };

  const navigateToStudentDashboard = () => {
    navigate("/student");
  };

  const storeLoginDataInStorage = (data: any) => {
    console.log(data);
    const userData = data?.data;
    setLocalStorage("loggedInUser", userData);
  };

  const errorFunction = (error: any) => {
    dispatch(showHideLoader(false));
    console.log(error);
  };

  const loginUsingEmailPassword = (
    inputObj: loginUsingEmailPasswordApiParams,
  ) => {
    console.log("login usin email and password API function");
    dispatch(showHideLoader(true));
    psApiService("student.login.email", inputObj)
      .then((result: any) => {
        setLocalStorage("loggedInUser", result?.data.data);
        dispatch(showHideLoader(false));
        if (result?.data.status === 200 || result?.data.status === 201) {
          studentNavigate.navigateToCongratulations();
          storeLoginDataInStorage(result?.data);
        } else {
          if (result?.data?.message) {
            const { message } = result?.data;
            showErrorToast(message, true);
            dispatch(loginErrorMessage(message));
          }
        }
      })
      .catch(errorFunction);
  };

  const getLoginOtpOnPhone = (inputObj: loginByPhoneApiParams) => {
    dispatch(showHideLoader(true));
    return psApiService("student.login.phone", inputObj)
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result.data.meta === null) {
            // navigateToProfileSteps();
          } else {
            // navigateToStudentDashboard();
          }
        } else {
          if (result?.data?.message) {
            const { message } = result?.data;
            // showErrorToast(message, true);
            // dispatch(loginErrorMessage(message));
          }
        }
        dispatch(showHideLoader(false));
        return result;
      })
      .catch(errorFunction);
  };
  const loginWithPhoneAndOtp = (inputObj: loginByPhoneApiParams) => {
    return psApiService("student.login.phoneAndOtp", inputObj)
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result.data.meta === null) {
            navigateToProfileSteps();
          } else {
            navigateToStudentDashboard();
          }
          storeLoginDataInStorage(result?.data);
        } else {
          if (result?.data?.message) {
            const { message } = result?.data;
            showErrorToast(message, true);
            // dispatch(loginErrorMessage(message));
          }
        }
        return result;
      })
      .catch(errorFunction);
  };
  // const getStudentProfileDetails = () => {
  //   return psApiService("student.profile.get", {})
  //     .then((result: any) => {
  //       if (result?.data.status === 200) {
  //         if (result.data.meta === null) {
  //           navigateToProfileSteps();
  //         } else {
  //           navigateToStudentDashboard();
  //         }
  //         storeLoginDataInStorage(result?.data);
  //       } else {
  //         if (result?.data?.message) {
  //           const { message } = result?.data;
  //           showErrorToast(message, true);
  //           // dispatch(loginErrorMessage(message));
  //         }
  //       }
  //       return result;
  //     })
  //     .catch((error) => console.log(error));
  // };
  return {
    loginUsingEmailPassword,
    getLoginOtpOnPhone,
    loginWithPhoneAndOtp,
    // getStudentProfileDetails,
  };
};
