import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import HeaderComponent from "../common/Header";
import CreateAccount from "../components/Authorization/CreateAccount";
import LoginByEmail from "../components/Authorization/LoginByEmail";
import LoginByPhoneNumber from "../components/Authorization/LoginByPhoneNumber";
import SelectLoginType from "../components/Authorization/SelectLoginType";
import { PSContainer } from "../views/Container";
import { JudgeDashboardView } from "../views/jdsh";
import { Authorization } from "./Authorization";
// import Congratulations from "../components/RegistrationSteps/Congratulations";
import Congratulations from "../components/Congratulations/Congratulation";
import BoardDetails from "../components/RegistrationSteps/BoardDetails";
import ExaminationDetails from "../components/RegistrationSteps/ExaminationDetails";
import GradeDetails from "../components/RegistrationSteps/GradeDetails";
import LanguageDetails from "../components/RegistrationSteps/LanguageDetails";
import PersonalDetails from "../components/RegistrationSteps/PersonalDetails";
import RegistrationContainer from "../components/RegistrationSteps/RegistrationContainer";
import SchoolDetails from "../components/RegistrationSteps/SchoolDetails";
import StepContainer from "../components/RegistrationSteps/StepsContainer";
import StudentDashboard from "../components/student/Dashboard";
import Student from "./Student";
// import { MainContainer } from "../views/MainContainer";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PSContainer />,
    // errorElement: <ErrorComponent />,
    children: [
      { index: true, element: <Navigate to="/authorization/login" replace /> },
      {
        path: "authorization",
        element: <Authorization />,
        children: [
          {
            path: "login",
            element: <SelectLoginType />,
          },
          {
            path: "email",
            element: <LoginByEmail />,
          },
          {
            path: "phone",
            element: <LoginByPhoneNumber />,
          },
          {
            path: "create",
            element: <CreateAccount />,
          },
        ],
      },
      { path: "Congratulations", element: <Congratulations /> },
      {
        path: "register",
        element: <RegistrationContainer />,
        children: [
          // { path: "congratulations", element: <Congratulations /> },

          {
            path: "steps",
            element: <StepContainer />,
            children: [
              { path: "personaldetails", element: <PersonalDetails /> },
              { path: "schooldetails", element: <SchoolDetails /> },
              { path: "boarddetails", element: <BoardDetails /> },
              { path: "languagedetails", element: <LanguageDetails /> },
              { path: "gradedetails", element: <GradeDetails /> },
              { path: "examinationdetails", element: <ExaminationDetails /> },
            ],
          },
        ],
      },
      {
        path: "student",
        element: <Student />,
        children: [
          {
            path: "dashboard",
            element: <StudentDashboard />,
          },
        ],
      },
      {
        element: <JudgeDashboardView />,
        children: [
          {
            path: "dashboard",
            element: <HeaderComponent />,
          },
        ],
      },
    ],
  },
]);

function PsRoutes() {
  return <RouterProvider router={router} />;
}

export default PsRoutes;

// function JudgeDashboardView() {
//   return <>
//       <Outlet />
//   </>
// }

// export default JudgeDashboardView;
