import { Grid2, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { PsButton, PsInput, StepBox } from "../../../lib/psElements/psElements";
import { useStudentNaviagation } from "../../../services/hooks/student/navigationHooks";
import { RootState } from "../../../services/storage/store";
import { personalDetail } from "../../../services/storage/student/profile";
import validation from "../../../services/validation";
import CountrySelect from "../../common/CountrySelect/CountrySelect";
import { GoBackArrowIos } from "../../common/GoBackButton/GoBackButton";
import StepsBar from "./StepsBar";

const PersonalDetails: FunctionComponent = () => {
  const [tempState, setTempState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    city: "",
    birthDate: "",
    phone: "",
    country: "",
  });
  const studentNaviage = useStudentNaviagation();
  const { profile } = useSelector((state: RootState) => state.studentProfile);
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: tempState,
    onSubmit: (values) => {
      const { firstName, lastName, email, city, birthDate, phone, country } =
        values;
      dispatch(
        personalDetail({
          ...profile,
          firstName,
          lastName,
          email,
          city,
          birthdate: birthDate,
          phone,
          country,
        }),
      );
      studentNaviage.navigateToSchoolDetails();
    },
    validationSchema: Yup.object({
      firstName: validation.schema.firstName,
      lastName: validation.schema.lastName,
      email: validation.schema.email,
      phone: validation.schema.mobile,
      city: validation.schema.city,
      birthDate: validation.schema.birthDateMin6Year,
    }),
  });

  const reassignTheStateValues = () => {
    setTempState({
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: profile.email,
      city: profile.city,
      birthDate: profile.birthdate,
      phone: profile.phoneno,
      country: profile.countrycode,
    });
  };

  useEffect(() => {
    reassignTheStateValues();
  }, []);

  useEffect(() => {
    reassignTheStateValues();
  }, [profile]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="personalDetails">
        <StepsBar currentStep={0} />
        <StepBox>
          <GoBackArrowIos
            handlerBackButton={() => {
              studentNaviage.navigateToDashboard();
            }}
            sx={{ ml: 2, mt: 2, mb: 1 }}
          />
          <Typography
            align="left"
            sx={{ ml: 3, fontWeight: 700, mb: 2.5 }}
            variant="h5"
          >
            Personal Details
          </Typography>
          <Grid2
            container
            spacing={{ xs: 2, md: 2.5 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ m: 3, mb: 21.75 }}
          >
            <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
              <PsInput
                label="First Name"
                {...formik.getFieldProps("firstName")}
                error={!!formik.errors.firstName}
                helperText={
                  formik.touched.firstName && formik.errors.firstName
                    ? formik.errors.firstName
                    : ""
                }
              />
            </Grid2>
            <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
              <PsInput
                label="Last Name"
                {...formik.getFieldProps("lastName")}
                error={!!formik.errors.lastName}
                helperText={
                  formik.touched.lastName && formik.errors.lastName
                    ? formik.errors.lastName
                    : ""
                }
              />
            </Grid2>
            <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
              <PsInput
                label="Email Address"
                type="email"
                {...formik.getFieldProps("email")}
                error={!!formik.errors.email}
                helperText={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : ""
                }
              />
            </Grid2>
            <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
              <Stack direction={"row"} gap={3}>
                <CountrySelect />
                <PsInput
                  label="Phone Number"
                  {...formik.getFieldProps("phone")}
                  error={!!formik.errors.phone}
                  helperText={
                    formik.touched.phone && formik.errors.phone
                      ? formik.errors.phone
                      : ""
                  }
                />
              </Stack>
            </Grid2>
            <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
              <PsInput label="Your City" {...formik.getFieldProps("city")} />
            </Grid2>
            <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
              <PsInput
                label="Birth Date"
                type="date"
                {...formik.getFieldProps("birthDate")}
                error={!!formik.errors.birthDate}
                helperText={
                  formik.touched.birthDate && formik.errors.birthDate
                    ? formik.errors.birthDate
                    : ""
                }
              />
            </Grid2>
          </Grid2>
        </StepBox>
        <Stack
          direction={"row"}
          gap={1}
          sx={{ mt: 4.5 }}
          alignSelf={"flex-end"}
        >
          <PsButton.Outlined
            variant="outlined"
            sx={{ width: 120 }}
            onClick={studentNaviage.navigateToDashboard}
          >
            Cancel
          </PsButton.Outlined>
          <PsButton.Primary
            variant="contained"
            sx={{ width: 240 }}
            type="submit"
          >
            Proceed
          </PsButton.Primary>
        </Stack>
      </div>
    </form>
  );
};
export default PersonalDetails;
