import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLocalStorage, showErrorToast } from "../commonFunctions";
import { showHideLoader } from "../storage/common";
import {
  personalDetail,
  setBoardList,
  setCoachingClassBranchList,
  setCoachingClassList,
  setExamDatesList,
  setGradeList,
  setLanguageList,
  setSchoolBranchList,
  setSchoolList,
} from "../storage/student/profile";
import psApiService from "./apiService";

interface loginUsingEmailPasswordApiParams {
  email: string;
  password: string;
  loginMethod: string;
}
interface loginByPhoneApiParams {
  mobile: string;
  otp: string;
  loginMethod: string;
}

export const useStudentProfileAPICalls = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToProfileSteps = () => {
    navigate("/register/steps/personaldetails");
  };
  const navigateToCongratulations = () => {
    navigate("/Congratulations");
  };

  const navigateToStudentDashboard = () => {
    navigate("/student");
  };

  const storeLoginDataInStorage = (data: any) => {
    console.log(data);
    const userData = data?.data;
    setLocalStorage("loggedInUser", userData);
  };

  const storeProfileDataInStorage = (data: any) => {
    const userData = data?.data;
    setLocalStorage("profileInfo", userData);
  };

  const errorFunction = (error: any) => {
    dispatch(showHideLoader(false));
    console.log(error);
  };

  const getSchoolList = () => {
    dispatch(showHideLoader(true));
    psApiService("student.profile.school.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setSchoolList([...result.data.data]));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const getSchoolBranchList = (schoolName: string) => {
    dispatch(showHideLoader(true));
    psApiService("student.profile.school.branch.get", { schoolName })
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setSchoolBranchList([...result.data.data]));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const getCoachingClassList = () => {
    dispatch(showHideLoader(true));
    psApiService("student.profile.coachingclass.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setCoachingClassList([...result.data.data]));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const getCoachingClassBranchList = (className: string) => {
    dispatch(showHideLoader(true));
    psApiService("student.profile.coachingclass.branch.get", { className })
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setCoachingClassBranchList([...result.data.data]));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const getBoardDetails = () => {
    dispatch(showHideLoader(true));
    psApiService("student.profile.board.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setBoardList(result.data.data));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
    // dispatch(
    //   setBoardList([
    //     {
    //       boardName: "CBSE",
    //       boardLogoUrl: null,
    //       boardUrl: null,
    //     },
    //     {
    //       boardName: "ICSE",
    //       boardLogoUrl: null,
    //       boardUrl: null,
    //     },
    //     {
    //       boardName: "GSEB/Gujarat",
    //       boardLogoUrl: null,
    //       boardUrl: null,
    //     },
    //     {
    //       boardName: "KSEAB/Karnataka",
    //       boardLogoUrl: null,
    //       boardUrl: null,
    //     },
    //   ])
    // );
  };
  const getLanguageDetails = () => {
    dispatch(showHideLoader(true));
    psApiService("student.profile.language.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setLanguageList(result.data.data));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
    dispatch(setLanguageList(["English", "Hindi", "Gujarati", "Marathi"]));
    // dispatch(setLanguageList(["one", "two", "three", "four"]));
    // dispatch(setLanguageList(["five", "six", "seven", "nine"]));
    // dispatch(setLanguageList(["ten", "elavon", "twelve", "thirteen"]));
  };
  const getGradeDetails = () => {
    dispatch(showHideLoader(true));
    psApiService("student.profile.grade.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setGradeList(result.data.data));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
    dispatch(setGradeList(["7", "8", "9", "10", "11", "12"]));
    // dispatch(setLanguageList(["one", "two", "three", "four"]));
    // dispatch(setLanguageList(["five", "six", "seven", "nine"]));
    // dispatch(setLanguageList(["ten", "elavon", "twelve", "thirteen"]));
  };
  const getExamDateList = () => {
    dispatch(showHideLoader(true));
    psApiService("student.profile.examdate.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setExamDatesList(result.data.data));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const getStudentProfileDetails = () => {
    dispatch(showHideLoader(true));
    return psApiService("student.profile.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          const data = result.data?.data;
          console.log("parsing data ", data);
          const personalDetailObject = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            bio: data.bio,
            class: {
              name: data.classDto.name,
              branch: data.classDto.branch,
              address: data.classDto.address,
            },
            isActive: data.isActive,
            isCustomer: data.isCustomer,
            language: data.language,
            phoneno: data.phoneNumber,
            photoUrl: data.photoUrl,
            school: {
              name: data.school.name,
              branch: data.school.branch,
              address: data.school.address,
            },
            socialLinks: data.socialLinks,
            userAddress: data.userAddress,
            city: data.userAddress?.city || "",
            userType: data.userType,
          };
          dispatch(personalDetail(personalDetailObject));
          storeProfileDataInStorage(result?.data);
        } else {
          if (result?.data?.message) {
            const { message } = result?.data;
            showErrorToast(message, true);
          }
        }
        dispatch(showHideLoader(false));
        return result;
      })
      .catch(errorFunction);
  };

  return {
    getBoardDetails,
    getLanguageDetails,
    getGradeDetails,
    getStudentProfileDetails,
    getSchoolList,
    getSchoolBranchList,
    getCoachingClassList,
    getCoachingClassBranchList,
    getExamDateList,
  };
};
