import { useNavigate } from "react-router-dom";

export const useStudentNaviagation = () => {
  const navigate = useNavigate();

  const navigateToCongratulations = () => {
    navigate("/Congratulations");
  };

  const navigateToStudentDashboard = () => {
    navigate("/student");
  };

  const navigateToProfileSteps = () => {
    navigate("/register/steps/personaldetails");
  };

  const navigateToSchoolDetails = () => {
    navigate("/register/steps/schooldetails");
  };
  const navigateToDashboard = () => {
    navigate("/student/dashboard");
  };

  const navigateToStep1PersonalDetails = () => {
    navigate("/register/steps/personaldetails");
  };

  const navigateToBoardDetails = () => {
    navigate("/register/steps/boarddetails");
  };

  return {
    navigateToCongratulations,
    navigateToStudentDashboard,
    navigateToSchoolDetails,
    navigateToProfileSteps,
    navigateToDashboard,
    navigateToStep1PersonalDetails,
    navigateToBoardDetails,
  };
};
